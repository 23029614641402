<template>
  <div class="uploadImages">
    <div v-for="(item, index) in fileLists" :key="index" class="demo-upload-list">
      <template>
        <img :src="item.url">
        <div class="demo-upload-list-cover">
          <i class="el-icon-zoom-in" @click="handlePictureCardPreview(item)" />
          <i v-if="showDelete" class="el-icon-delete" @click="removeImg(item)" />
        </div>
      </template>
    </div>
    <el-upload
      v-if="fileLists.length < limit && picDisable === false"
      ref="upload"
      class="avatar-uploader"
      :before-upload="beforeUpload"
      :action="uploadApi"
      :headers="headers"
      :show-file-list="false"
      :on-success="handleAvatarSuccess"
    >
      <i class="el-icon-plus avatar-uploader-icon" />
    </el-upload>
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>
  </div>
</template>

<script>
import api from '@/store/API/api'

export default {
  name: 'UploadImg',
  props: {
    // 可上传文件数量
    limit: {
      type: String | Number,
      default: 1
    },
    // 文件列表
    uploadLists: {
      type: Array,
      default: () => {
        return []
      }
    },
    picDisable: {
      type: Boolean,
      default: () => {
        return false
      }
    }
  },
  data() {
    return {
      headers: { Authorization: sessionStorage.token },
      uploadApi: process.env.NODE_ENV === 'development' ? '/api' + api.fileUpload : process.env.VUE_APP_BASEURL + api.fileUpload,
      fileLists: [],
      fileIds: [],
      dialogVisible: false,
      dialogImageUrl: ''
    }
  },
  computed: {
    showDelete() {
      return this.picDisable === false
    }
  },
  watch: {
    uploadLists(val) {
      this.fileLists = val
      this.fileIds = this.fileLists.map((item) => item.id)
    }
  },
  methods: {
    handleAvatarSuccess(res, file) {
      if (res.code == 200) {
        this.$message.success('上传成功')
        this.fileLists.push({
          id: res.data,
          url: URL.createObjectURL(file.raw)
        })
        this.fileIds = [...this.fileIds, res.data]
        this.$emit('uploadChange', this.fileIds)
      }
    },
    removeImg(data) {
      const _index = this.fileLists.findIndex((item) => item.id == data.id)
      this.fileLists.splice(_index, 1)
      this.fileIds = this.fileLists.map((item) => item.id)
      this.$emit('uploadChange', this.fileIds)
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    beforeUpload(file) {
      const lsLtSize = file.size / 1024 / 1024 < 5
      if (!lsLtSize) {
        this.$XModal.message({
          message: '上传图片大小不能超过5MB',
          status: 'warning'
        })
        return false
      }
      return true
    }
  }
}
</script>

<style lang="scss">
.uploadImages {
  display: flex;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
}
.avatar {
  width: 100px;
  height: 100px;
  display: block;
}
.demo-upload-list {
  display: inline-block;
  width: 100px;
  height: 100px;
  text-align: center;
  line-height: 100px;
  border: 1px solid transparent;
  border-radius: 4px;
  overflow: hidden;
  background: #fff;
  position: relative;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  margin-right: 4px;
}
.demo-upload-list img {
  width: 100%;
  height: 100%;
}
.demo-upload-list-cover {
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.6);
}
.demo-upload-list:hover .demo-upload-list-cover {
  display: block;
}
.demo-upload-list-cover i {
  color: #fff;
  font-size: 20px;
  cursor: pointer;
  margin: 0 2px;
}
</style>
